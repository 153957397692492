<!-- resources/js/Components/CookieBanner.vue -->
<template>
  <b-alert
    v-if="showBanner"
    variant="info"
    dismissible
    @dismissed="acceptCookies"
    class="fixed-bottom m-3 d-flex justify-content-center"
  >
    <div class="c-banner row d-flex justify-content-center align-items-center">
      <div class="col-auto my-2">
        We use essential cookies to enhance your experience and ensure the security of the website.
        <a href="https://www.iubenda.com/privacy-policy/76058140/cookie-policy" class="alert-link">Learn more</a>.
      </div>
      <div class="col-auto">
        <b-button class="btn btn-primary my-2" variant="primary" @click="acceptCookies">Got it</b-button>
      </div>
    </div>
  </b-alert>
</template>

<script>
export default {
  data() {
    return {
      showBanner: false,
    };
  },
  mounted() {
    if (!this.getCookie('cookie_consent')) {
      this.showBanner = true;
    }
  },
  methods: {
    acceptCookies() {
      this.setCookie('cookie_consent', 'accepted', 365);
      this.showBanner = false;
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/; SameSite=Lax; Secure";
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>

<style scoped>
.fixed-bottom {
  z-index: 1030;
}

.c-banner{
  border: 1px solid #bfbfbf;
  box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.1);
  width: 100vh;
  background-color: #fff;
  border-radius: 10px;
}
</style>
